<template>
    <v-card class="backgroundColor" :height="$vuetify.breakpoint.mdAndUp ? `100%` : `100vmax`" >
      <div 
      class="Welcometitle primary--text titleFontlg">
        Mes documents
      </div>

      <v-row
          no-gutters
          justify="center"
          id="documents"
      >
        <v-col
            cols="6"
            xl="1"
            md="3"
            sm="4"
            xs="6"
            class="pa-1"
        >
          <v-card
              :to="'/'"
              aria-label="book.nom"
              name="book.nom"
              @click="alert('hello')"
              class="mx-2 mb-6 "
              tile
              flat
              style="aspect-ratio:3/3"
              contain
              elevation="1"
          >
            <v-card-text align="center" class="text-center">
              <p>Mon super document</p>
              <v-icon x-large>
                mdi-file-pdf-box
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
            cols="6"
            xl="1"
            md="3"
            sm="4"
            xs="6"
            class="pa-1"
        >
          <v-card
              :to="'/'"
              aria-label="book.nom"
              name="book.nom"
              @click="alert('hello')"
              class="mx-2 mb-6 "
              tile
              flat
              style="aspect-ratio:3/3"
              contain
              elevation="1"
          >
            <v-card-text align="center" class="text-center">
              <p>Mon super document</p>
              <v-icon x-large>
                mdi-note-outline
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
            cols="6"
            xl="1"
            md="3"
            sm="4"
            xs="6"
            class="pa-1"
        >
          <v-card
              :to="'/'"
              aria-label="book.nom"
              name="book.nom"
              @click="alert('hello')"
              class="mx-2 mb-6 "
              tile
              flat
              style="aspect-ratio:3/3"
              contain
              elevation="1"
          >
            <v-card-text align="center" class="text-center">
              <p>Mon super document</p>
              <v-icon x-large>
                mdi-book
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
            cols="6"
            xl="1"
            md="3"
            sm="4"
            xs="6"
            class="pa-1"
        >
          <v-card
              :to="'/'"
              aria-label="book.nom"
              name="book.nom"
              @click="alert('hello')"
              class="mx-2 mb-6 "
              tile
              flat
              style="aspect-ratio:3/3"
              contain
              elevation="1"
          >
            <v-card-text align="center" class="text-center">
              <p>Mon super document</p>
              <v-icon x-large>
                mdi-file-image
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-card>
</template>

<script>

export default {
  data: () => ({}),
  components: {

  },
  methods: {},
};
</script>
  
<style scoped>

.Welcometitle {
  text-align: center;
  margin-bottom: 16px;
  margin-top: 50px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.titleFontlg {
  font-size: 28px;
}
</style>
